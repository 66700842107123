import React, { useState, useCallback } from 'react';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    // Fonction de défilement vers la section newsletter pour éviter la duplication de code
    const scrollToNewsletter = useCallback(() => {
        const newsletterSection = document.getElementById("newsletter");
        if (newsletterSection) {
            newsletterSection.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <header className="flex justify-between items-center p-4 bg-white shadow-md">
            {/* Logo à gauche */}
            <div className="text-2xl font-bold">
                <span className="text-black">la </span><span className="text-black">Vijy</span>
            </div>

            {/* Conteneur des liens de navigation et du bouton */}
            <div className="hidden md:flex md:items-center md:space-x-6 ml-auto">
                <nav className="flex space-x-6">
                    <a href="#testimonials" className="text-gray-700 hover:text-gray-900">Les turbulences du freelance</a>
                    <a href="#solutions" className="text-gray-700 hover:text-gray-900">Nos solutions</a>
                </nav>

                <button
                    onClick={scrollToNewsletter}
                    className="bg-orange-500 text-white px-4 py-2 rounded-full"
                >
                    Je veux décoller !
                </button>
            </div>

            {/* Menu Mobile */}
            <div className="md:hidden">
                <button
                    onClick={toggleMenu}
                    className="bg-orange-500 text-white px-4 py-2 rounded-full"
                >
                    Menu
                </button>
            </div>

            {/* Menu déroulant pour Mobile */}
            {isMenuOpen && (
                <nav className="absolute top-16 left-0 w-full bg-white shadow-md md:hidden flex flex-col items-center space-y-4 p-4">
                    <a href="#testimonials" onClick={toggleMenu} className="text-gray-700 hover:text-gray-900">
                        Les turbulences du freelance
                    </a>
                    <a href="#solutions" onClick={toggleMenu} className="text-gray-700 hover:text-gray-900">
                        Nos solutions
                    </a>
                    <button
                        onClick={() => {
                            toggleMenu();
                            scrollToNewsletter();
                        }}
                        className="bg-orange-500 text-white px-4 py-2 rounded-full"
                    >
                        Je veux décoller !
                    </button>
                </nav>
            )}
        </header>
    );
};

export default React.memo(Header);
