import React from 'react';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#2B2B2B' }} className="text-white py-8">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
                {/* Logo and company name */}
                <div className="text-left">
                    <h2 className="text-2xl font-bold mb-2">la <span className="text-white">Vijy</span></h2>
                </div>

                {/* Links Section - Vijy */}
                <div className="text-left">
                    <h3 className="font-semibold text-lg mb-2">VIJY</h3>
                    <ul className="flex flex-col space-y-1">
                        <li><a href="#" className="text-white hover:text-gray-400 no-underline">Notre raison d'être</a></li>
                        <li><a href="#solutions" className="text-white hover:text-gray-400 no-underline">Nos solutions</a></li>
                        <li><a href="#newsletter" className="text-white hover:text-gray-400 no-underline">Prêts à décoller ?</a></li>
                    </ul>
                </div>

                {/* Links Section - A Propos */}
                <div className="text-left">
                    <h3 className="font-semibold text-lg mb-2">A PROPOS</h3>
                    <ul className="flex flex-col space-y-1">
                        <li><a href="#" className="text-white hover:text-gray-400 no-underline">Terms & Conditions</a></li>
                        <li><a href="#" className="text-white hover:text-gray-400 no-underline">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            {/* Copyright Section */}
            <div className="mt-6 text-center text-gray-400">
                <p>Copyright © 2024 Vijy</p>
            </div>
        </footer>
    );
};

export default Footer;
