import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import StatisticsSection from './components/StatisticsSection';
import TestimonialsSection from './components/TestimonialsSection';
import FeaturesSection from './components/FeaturesSection';
import NewsletterSection from './components/NewsletterSection';
import TeamSection from './components/TeamSection';
import Footer from './components/Footer';

function App() {
    return (
        <div className="font-sans">
            <Header />
            <HeroSection />
            <StatisticsSection />
            <TestimonialsSection />
            <FeaturesSection />
            <NewsletterSection />
            <TeamSection />
            <Footer />
        </div>
    );
}

export default App;
