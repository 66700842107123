import React from 'react';
import CountUp from 'react-countup';

const StatisticsSection = () => {
    const stats = [
        { id: 1, value: 1.3, suffix: 'M', text: 'freelances en France...' },
        { id: 2, value: 83, suffix: '%', text: 'des freelances ouvrent...' },
        { id: 3, value: 14, suffix: '%', text: 'Augmentation annuelle...' },
        { id: 4, value: 51, suffix: '%', text: 'Éprouvent des difficultés...' },
    ];

    return (
        <section className="py-20" style={{ backgroundColor: '#82C8E5' }}>
            <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 text-center">
                {stats.map((stat, index) => (
                    <div
                        key={stat.id}
                        className="fadeIn text-white transition duration-700 ease-out"
                        style={{
                            animationDelay: `${index * 0.2}s`,
                            animationName: 'fadeIn',
                            animationFillMode: 'forwards',
                        }}
                    >
                        <h3 className="text-4xl font-bold">
                            <CountUp
                                start={0}
                                end={stat.value}
                                suffix={stat.suffix}
                                decimals={1}   // Décimal pour les freelances en france
                                duration={7}   // Durée décompte
                            />
                        </h3>
                        <p>{stat.text}</p>
                    </div>
                ))}
            </div>

            {/* CSS pour le fadeIn */}
            <style jsx>{`
                @keyframes fadeIn {
                    0% { opacity: 0; transform: translateY(10px); }
                    100% { opacity: 1; transform: translateY(0); }
                }
            `}</style>
        </section>
    );
};

export default StatisticsSection;
