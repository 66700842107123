import React from 'react';

const NewsletterSection = () => {
    return (
        <section id="newsletter" className="bg-blue-100 py-10 text-center">
            <h2 className="text-2xl font-bold">Prêt pour le décollage ?</h2>
            <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe57suButJuTQVkH0RgGrjxvktQD9pIvwpPAGrsE5hGMie2Sw/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-orange-500 text-white px-6 py-3 rounded-full hover:bg-orange-600 transition duration-300 ease-in-out inline-block"
            >
                Je veux vous aider !
            </a>
            <p className="mt-2 text-sm text-gray-600">Aidez-nous en remplissant le questionnaire !</p>
        </section>
    );
};

export default NewsletterSection;