import React from 'react';
import KeyVisualImage from '../assets/images/KEY VISUAL.jpg';

const HeroSection = () => {
    const scrollToNewsletter = () => {
        const newsletterSection = document.getElementById("newsletter");
        if (newsletterSection) {
            newsletterSection.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <section className="py-20 bg-white text-center min-h-screen flex flex-col justify-center">
            <div className="container mx-auto px-4 lg:px-8">
                <h1 className="text-3xl lg:text-5xl font-bold leading-tight mb-4">
                    Votre <span className="text-blue-500">copilote de confiance</span> pour
                    <br />
                    votre aventure freelance
                </h1>
                <p className="text-gray-700 max-w-2xl mx-auto mb-6">
                    Pensé par des freelances, pour des freelances, Vijy prépare le terrain pour que chacun puisse s’élever sereinement.
                    Avec un système de validation des compétences et l’objectif de créer un collectif d’entraide, nous vous aidons à
                    survoler les peurs du quotidien et à naviguer dans un ciel dégagé.
                </p>
                <button
                    className="bg-orange-500 text-white px-6 py-3 rounded-full hover:bg-orange-600"
                    onClick={scrollToNewsletter}
                >
                    Je veux décoller !
                </button>

                {/* Section Image */}
                <div className="mt-10 flex justify-center">
                    <img
                        src={KeyVisualImage}
                        alt="Hero Background"
                        loading="lazy"
                        className="rounded-[48px] w-[1280px] h-[576px] object-cover transform -scale-x-100 shadow-lg"
                    />
                </div>
            </div>
        </section>
    );
};

export default HeroSection;