import React, { memo } from 'react';
import EmmaPhoto from '../assets/images/emmaLtémoin.png';
import AntoinePhoto from '../assets/images/antoineMtémoin.png';
import LuciePhoto from '../assets/images/lucieTtémoin.png';

const testimonials = [
    {
        id: 1,
        image: EmmaPhoto,
        name: "Emma L.",
        role: "Consultante en Marketing",
        text: "Quand je me suis lancée, j'avais peur de ne pas trouver de clients et de rester sans revenus. Travailler seule peut vraiment être angoissant au début."
    },
    {
        id: 2,
        image: AntoinePhoto,
        name: "Antoine M.",
        role: "Développeur Full Stack",
        text: "Je me demandais sans cesse si mes tarifs étaient justes. Trop cher, pas assez ? Difficile de savoir quand on est seul pour prendre ces décisions."
    },
    {
        id: 3,
        image: LuciePhoto,
        name: "Lucie T.",
        role: "Brand & Visual Designer",
        text: "Je passe énormément de temps sur la gestion administrative que ça empiète sur mon travail créatif. C’est un vrai challenge de tout gérer soi-même."
    }
];

const TestimonialCard = memo(({ image, name, role, text }) => (
    <div className="bg-blue-50 p-6 rounded-lg shadow-md max-w-sm w-full">
        <p className="text-gray-700">{text}</p>
        <div className="flex items-center mt-4 bg-blue-100 rounded-lg p-4">
            <img src={image} alt={name} loading="lazy" className="w-12 h-12 rounded-full mr-4" />
            <div>
                <p className="font-semibold">{name}</p>
                <p className="text-sm text-gray-600">{role}</p>
            </div>
        </div>
    </div>
));

const TestimonialsSection = () => {
    return (
        <section id="testimonials" className="py-20 bg-white text-center">
            <h2 className="text-2xl font-semibold mb-6">
                On a tous ressenti ces <span className="text-blue-500">turbulences...</span>
            </h2>
            <div className="container mx-auto flex flex-wrap justify-center gap-6">
                {testimonials.map((testimonial) => (
                    <TestimonialCard
                        key={testimonial.id}
                        image={testimonial.image}
                        name={testimonial.name}
                        role={testimonial.role}
                        text={testimonial.text}
                    />
                ))}
            </div>
        </section>
    );
};

export default memo(TestimonialsSection);

