import React from 'react';
import ValidationIcon from '../assets/images/validationetcertification.svg';
import FormationIcon from '../assets/images/formationcertifie.svg';
import AffiliationIcon from '../assets/images/Affiliation et cooptation.svg';

const features = [
    {
        id: 1,
        title: "Validation et certifications",
        description: "Faites valider et certifier vos compétences pour les valoriser auprès des entreprises.",
        icon: ValidationIcon
    },
    {
        id: 2,
        title: "Formations certifiées",
        description: "Restez à jour grâce à un apprentissage continu et validé, pour toujours rester compétitif.",
        icon: FormationIcon
    },
    {
        id: 3,
        title: "Affiliation et cooptation",
        description: "Intégrez un réseau solidaire où chaque freelance peut recommander et coopter ses pairs.",
        icon: AffiliationIcon
    }
];



const FeaturesSection = () => {
    return (
        <section id="solutions" className="py-20" style={{ backgroundColor: '#f3f4f6' }}>
            <div className="container mx-auto flex flex-col lg:flex-row items-start lg:items-center px-4 lg:px-8">
                {/* Texte et bouton */}
                <div className="lg:w-1/2 mb-10 lg:mb-0">
                    <h2 className="text-3xl font-semibold mb-4">
                        Les atouts Vijy pour <span className="text-blue-500">naviguer en confiance</span>
                    </h2>
                    <p className="text-gray-700 mb-6 max-w-md">
                        Chez Vijy, nous mettons en place les instruments de bord essentiels pour alléger la charge des freelances et les accompagner vers un vol stable et sécurisé.
                    </p>
                    <button className="bg-orange-500 text-white px-6 py-3 rounded-full hover:bg-orange-600 transition duration-300 ease-in-out">
                        Je veux décoller !
                    </button>
                </div>

                {/* Liste des fonctionnalités à droite */}
                <div className="lg:w-1/2 grid grid-cols-1 gap-6">
                    {features.map((feature) => (
                        <div key={feature.id} className="flex items-start transition-transform duration-300 hover:scale-105">
                            <img src={feature.icon} alt={feature.title} className="w-12 h-12 mr-4" />
                            <div>
                                <h3 className="text-xl font-bold mb-1">{feature.title}</h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;
