import React from 'react';
import GregoryPhoto from '../assets/images/Gregory.png';
import ArthurPhoto from '../assets/images/Arthur.png';
import ManonPhoto from '../assets/images/Manon.png';
import AlexisBPhoto from '../assets/images/alexisb.png';
import DenisPhoto from '../assets/images/Denis.png';

const teamMembers = [
    { id: 1, name: "Grégory Nédélec", role: "CEO & Strategist", imageUrl: GregoryPhoto },
    { id: 2, name: "Arthur Sèche", role: "Co-founder & Relations Institutionnelles", imageUrl: ArthurPhoto },
    { id: 3, name: "Manon Pitte", role: "Product Design & UX Design", imageUrl: ManonPhoto },
    { id: 4, name: "Alexis Berne", role: "Développeur FullStack & DevOps", imageUrl: AlexisBPhoto },
    { id: 5, name: "Denis Constant", role: "Brand & UI Design", imageUrl: DenisPhoto },
];

const TeamSection = () => {
    return (
        <section id="team" className="py-20 bg-gray-50 text-center">
            <h2 className="text-2xl font-semibold mb-10">Un équipage de freelances</h2>
            <p className="text-gray-600 mb-10 max-w-2xl mx-auto">
                Nous sommes des experts et passionnés du numérique, reconnus pour nos approches et notre qualité pédagogique.
            </p>
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
                {teamMembers.map((member) => (
                    <div key={member.id} className="bg-white shadow-lg rounded-lg p-6 flex items-center">
                        <img
                            src={member.imageUrl}
                            alt={member.name}
                            className="w-24 h-24 rounded-full object-cover"
                        />
                        <div className="text-left ml-4">
                            <h3 className="text-lg font-semibold">{member.name}</h3>
                            <p className="text-gray-500">{member.role}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TeamSection;